import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios, { AxiosError } from "axios";

interface BackendErrorResponse {
  message: string;
  errors?: FieldError[] | null;
}

interface FieldError {
  [key: string]: string[];
}

declare type ErrorObject = Error | AxiosError;
declare type SetFieldErrorCallback = (field: string, message: string) => void;

export default class Notify {
  public static apiError(
    error: ErrorObject,
    setFieldError?: SetFieldErrorCallback | null
  ): void {
    if (setFieldError != null) {
      Notify.formErrors(error, setFieldError);
      return;
    }
    Notify.modalError(error);
  }

  private static formErrors(
    error: ErrorObject,
    setFieldError: SetFieldErrorCallback
  ): void {
    if (axios.isAxiosError(error) && error.response != null) {
      const response = error.response.data as BackendErrorResponse;

      if (response.errors != null) {
        for (const field in response.errors) {
          setFieldError(field, response.errors[field][0].toString());
        }
      }
    }
  }

  private static modalError(error: ErrorObject): void {
    Swal.fire({
      html: Notify.resolveErrorMessage(error),
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "OK",
      customClass: {
        confirmButton: "btn fw-bold btn-light-danger",
      },
    });
  }

  private static resolveErrorMessage(error: ErrorObject): string {
    if (axios.isAxiosError(error) && error.response != null) {
      let errors = "";

      if (error.response.data.errors) {
        errors = "Errors:";
        for (const field in error.response.data.errors) {
          for (const fieldError in error.response.data.errors[field]) {
            errors += "<br>" + field + " - " + error.response.data.errors[field][fieldError];
          }
        }
      }

      return "<h4 style='margin-bottom: 15px;'>" + error.response.data.message + "</h4>" + errors;
    }

    return error.message;
  }
}
